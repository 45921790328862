export const chain_info = {
    "56" : {
        "symbol" : "BNB",
        "chainId" : 56,
        "ChainName" : "Binance Smart Chain Mainnet",
        "rpcUrls" : "https://bsc-dataseed.binance.org/",
        "nft_address":"0x0cc3310f928daf675a08108b6f69c92b77808c63"
    },
    "97" : {
        "symbol" : "TBNB",
        "chainId" : 97,
        "ChainName" : "Binance Smart Chain Testnet",
        "rpcUrls" : "https://data-seed-prebsc-1-s1.binance.org:8545/",
        "nft_address":"0xd181830a9651396e02a1cf0a307bfd8423402aa3"
     //"nft_address":"0xc2cf2fc3a2d66fb36253250a05f849a7438fb80d"

        
    }
};